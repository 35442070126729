*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #EFF1F5;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* custom scrollbar */
*::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track-piece{
  background-color: #FFF;
}

*::-webkit-scrollbar-thumb{
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: .1px solid #B7B7B7;
}

*::-webkit-scrollbar-thumb:hover{
  background-color: #909090;
}